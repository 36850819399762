import { forEach, map, filter, toArray, orderBy } from 'lodash'

import itemTypes from '../../../shared/itemTypes'
import { acceptedDropByUserType } from './abilityOrder'

// deprecated
export const dataToOrder = (data = null) => {
  const orderListParsed = []
  const orderList = {}
  let approved = data.approved
  let cancelled = data.cancelled
  let draft = data.draft
  let inProgress = data.inProgress
  let inReview = data.inReview
  let onHold = data.onHold
  let queue = data.queue
  let readyToGo = data.readyToGo

  orderListParsed.push(...dataStatusParse('approved', approved))
  orderListParsed.push(...dataStatusParse('cancelled', cancelled))
  orderListParsed.push(...dataStatusParse('draft', draft))
  orderListParsed.push(...dataStatusParse('inProgress', inProgress))
  orderListParsed.push(...dataStatusParse('inReview', inReview))
  orderListParsed.push(...dataStatusParse('onHold', onHold))
  orderListParsed.push(...dataStatusParse('queue', queue))
  orderListParsed.push(...dataStatusParse('readyToGo', readyToGo))

  orderListParsed.forEach((val) => {
    orderList[val.id] = val
  })

  return orderList
}

// deprecated
const dataStatusParse = (typeStatus, data = []) => {
  const orderListParsed = []

  if (data.length > 0) {
    data.forEach((x) => {
      let orderStatus = ''
      let orderState = ''
      let orderStateStatus = ''

      switch (typeStatus) {
        case 'approved':
          orderStatus = itemTypes.APPROVED
          orderState = itemTypes.ACTIVE_CARD
          orderStateStatus = itemTypes.APPROVED
          break
        case 'cancelled':
          orderStatus = itemTypes.CANCELLED
          orderState = itemTypes.ACTIVE_CARD
          orderStateStatus = itemTypes.CANCELLED
          break
        case 'inProgress':
          orderStatus = itemTypes.IN_PROGRESS
          orderState = itemTypes.ACTIVE_CARD
          orderStateStatus = itemTypes.IN_PROGRESS
          break
        case 'inReview':
          orderStatus = itemTypes.IN_REVIEW
          orderState = itemTypes.ACTIVE_CARD
          orderStateStatus = itemTypes.IN_REVIEW
          break
        case 'onHold':
          orderStatus = itemTypes.ON_HOLD
          orderState = itemTypes.ACTIVE_CARD
          orderStateStatus = itemTypes.ON_HOLD
          break
        case 'queue':
          orderStatus = itemTypes.NEW_ORDER
          orderState = itemTypes.QUEUE_CARD
          orderStateStatus = itemTypes.QUEUE_CARD
          break
        case 'draft':
          orderStatus = itemTypes.NEW_ORDER
          orderState = itemTypes.DRAFT_CARD
          orderStateStatus = itemTypes.DRAFT_CARD
          break
        default:
          //readytogo
          orderStatus = itemTypes.NEW_ORDER
          orderState = itemTypes.ACTIVE_CARD
          orderStateStatus = itemTypes.ACTIVE_CARD
          break
      }

      orderListParsed.push({
        ...x,
        orderStatus: orderStatus,
        orderState: orderState,
        orderStateStatus: orderStateStatus,
      })
    })
  }

  return orderListParsed
}

// deprecated
export const addTasksToColumn = (columns, tasks, user) => {
  const newCols = { ...columns }
  forEach(columns, (col) => {
    if (col.typeStatus === itemTypes.NEW_ORDER) {
      const draft = toArray(
        map(
          orderBy(
            filter(
              tasks,
              (t) =>
                t.orderStatus === col.typeStatus &&
                t.orderState === itemTypes.DRAFT_CARD
            ),
            'createdAt',
            'desc'
          ),
          (t) => t.id
        )
      )
      const queue = toArray(
        map(
          orderBy(
            filter(
              tasks,
              (t) =>
                t.orderStatus === col.typeStatus &&
                t.orderState === itemTypes.QUEUE_CARD
            ),
            'createdAt',
            'desc'
          ),
          (t) => t.id
        )
      )
      const active = toArray(
        map(
          orderBy(
            filter(
              tasks,
              (t) =>
                t.orderStatus === col.typeStatus &&
                t.orderState === itemTypes.ACTIVE_CARD
            ),
            'createdAt',
            'desc'
          ),
          (t) => t.id
        )
      )
      col.taskIds = [...active, ...queue, ...draft]
    } else {
      col.taskIds = toArray(
        map(
          orderBy(
            filter(tasks, (t) => t.orderStatus === col.typeStatus),
            'createdAt',
            'desc'
          ),
          (t) => t.id
        )
      )
    }

    if (user) {
      const acceptedDrop = acceptedDropByUserType(user.role, col.typeStatus)
      col.acceptedDrop = acceptedDrop
    }
  })

  return newCols
}

export const renderColumnTasks = (columns, columnSortedTasks, user) => {
  let newCols = { ...columns }

  forEach(newCols, (col) => {
    switch (col.typeStatus) {
      case itemTypes.AWAITING_QUOTE:
        col.taskIds = columnSortedTasks.awaitingQuote
        break
      case itemTypes.AWAITING_APPROVAL:
        col.taskIds = columnSortedTasks.awaitingApproval
        break
      case itemTypes.REJECTED:
        col.taskIds = columnSortedTasks.rejected
        break
      case itemTypes.BEFORE_READY_TO_GO:
        col.taskIds = columnSortedTasks.beforeReadyToGo
        break
      case itemTypes.NEW_ORDER:
        col.taskIds = columnSortedTasks.newOrder
        break

      case itemTypes.IN_PROGRESS:
        col.taskIds = columnSortedTasks.inProgress
        break

      case itemTypes.IN_REVIEW:
        col.taskIds = columnSortedTasks.inReview
        break

      case itemTypes.ON_HOLD:
        col.taskIds = columnSortedTasks.onHold
        break

      case itemTypes.APPROVED:
        col.taskIds = columnSortedTasks.approved
        break

      case itemTypes.COMPLETED:
        col.taskIds = columnSortedTasks.completed
        break

      case itemTypes.CANCELLED:
        col.taskIds = columnSortedTasks.cancelled
        break

      default:
        col.taskIds = []
        break
    }

    if (user) {
      const acceptedDrop = acceptedDropByUserType(user.role, col.typeStatus)
      col.acceptedDrop = acceptedDrop
    }
  })

  return newCols
}

export const generateOrderParams = (preset) => {
  if (!preset) {
    return null
  }

  const result = {
    clientId:
      preset.client !== null && preset.client.length > 0 ? preset.client : null,
    creatorId:
      preset.creator !== null && preset.creator.length > 0
        ? preset.creator
        : null,
    customerId:
      preset.customer !== null && preset.customer.length > 0
        ? preset.customer
        : null,
    orderProgress:
      preset.orderProgress &&
      preset.orderProgress !== null &&
      preset.orderProgress.length > 0
        ? preset.orderProgress
        : null,
    managerId:
      preset.manager !== null && preset.manager.length > 0
        ? preset.manager
        : null,
    operatorId:
      preset.operator !== null && preset.operator.length > 0
        ? preset.operator
        : null,
    serviceCategory:
      preset.serviceCategory !== null && preset.serviceCategory.length > 0
        ? preset.serviceCategory
        : null,
    serviceType:
      preset.serviceType !== null && preset.serviceType.length > 0
        ? preset.serviceType
        : null,
    createdAtStart:
      preset.createdDate && preset.createdDate.startDate
        ? `${preset.createdDate.startDate} 00:00:00`
        : null,
    createdAtEnd:
      preset.createdDate && preset.createdDate.endDate
        ? `${preset.createdDate.endDate} 24:00:00`
        : null,
    dueDateStart:
      preset.dueDate && preset.dueDate.startDate
        ? `${preset.dueDate.startDate} 00:00:00`
        : null,
    dueDateEnd:
      preset.dueDate && preset.dueDate.endDate
        ? `${preset.dueDate.endDate} 24:00:00`
        : null,
    queueTime:
      preset.queueTime &&
      preset.queueTime !== null &&
      preset.queueTime.length > 0
        ? preset.queueTime
        : null,
    workTime:
      preset.workTime && preset.workTime !== null && preset.workTime.length > 0
        ? preset.workTime
        : null,
    customSearch: preset.customSearch,
    dynamic: preset.dynamic,
  }

  return result
}

export const urlSearchParams = (filters) => {
  const params = new URLSearchParams()

  if (filters) {
    forEach(filters, (value, key) => {
      if (value && typeof value === 'string' && value !== '') {
        params.append(key, value)
      } else if (value && typeof value === 'object' && value.length > 0) {
        params.append(key, value.join(','))
      } else if (typeof value === 'number') {
        params.append(key, value)
      }
    })
  }

  return params
}

export const clearTasksToColumn = (columns) => {
  const newCols = { ...columns }
  forEach(columns, (col) => {
    col.taskIds = []
  })

  return newCols
}

export const updateStateCard = (typeStatus) => {
  let orderStatus = ''
  let orderState = ''
  let orderStateStatus = ''

  switch (typeStatus) {
    case itemTypes.APPROVED:
      orderStatus = itemTypes.APPROVED
      orderState = itemTypes.ACTIVE_CARD
      orderStateStatus = itemTypes.APPROVED
      break
    case itemTypes.CANCELLED:
      orderStatus = itemTypes.CANCELLED
      orderState = itemTypes.ACTIVE_CARD
      orderStateStatus = itemTypes.CANCELLED
      break
    case itemTypes.IN_PROGRESS:
      orderStatus = itemTypes.IN_PROGRESS
      orderState = itemTypes.ACTIVE_CARD
      orderStateStatus = itemTypes.IN_PROGRESS
      break
    case itemTypes.IN_REVIEW:
      orderStatus = itemTypes.IN_REVIEW
      orderState = itemTypes.ACTIVE_CARD
      orderStateStatus = itemTypes.IN_REVIEW
      break
    case itemTypes.ON_HOLD:
      orderStatus = itemTypes.ON_HOLD
      orderState = itemTypes.ACTIVE_CARD
      orderStateStatus = itemTypes.ON_HOLD
      break
    case itemTypes.QUEUE_CARD:
      orderStatus = itemTypes.NEW_ORDER
      orderState = itemTypes.QUEUE_CARD
      orderStateStatus = itemTypes.QUEUE_CARD
      break
    case itemTypes.DRAFT_CARD:
      orderStatus = itemTypes.NEW_ORDER
      orderState = itemTypes.DRAFT_CARD
      orderStateStatus = itemTypes.DRAFT_CARD
      break
    case itemTypes.AWAITING_QUOTE:
      orderStatus = itemTypes.AWAITING_QUOTE
      orderState = itemTypes.DRAFT_CARD
      orderStateStatus = itemTypes.DRAFT_CARD
      break
    case itemTypes.AWAITING_APPROVAL:
      orderStatus = itemTypes.AWAITING_APPROVAL
      orderState = itemTypes.DRAFT_CARD
      orderStateStatus = itemTypes.DRAFT_CARD
      break
    case itemTypes.REJECTED:
      orderStatus = itemTypes.REJECTED
      orderState = itemTypes.DRAFT_CARD
      orderStateStatus = itemTypes.DRAFT_CARD
      break
    case itemTypes.COMPLETED:
      orderStatus = itemTypes.COMPLETED
      orderState = itemTypes.ACTIVE_CARD
      orderStateStatus = itemTypes.COMPLETED
      break
    default:
      //readytogo
      orderStatus = itemTypes.NEW_ORDER
      orderState = itemTypes.ACTIVE_CARD
      orderStateStatus = itemTypes.ACTIVE_CARD
      break
  }

  return { orderStateStatus, orderState, orderStatus }
}

export const getOrdersOnWorkingTime = (columnSortedTasks, orderList, user) => {
  let ordersOnWorkingTime = []
  if (columnSortedTasks.inProgress.length > 0) {
    for (var i = 0; i < columnSortedTasks.inProgress.length; i++) {
      if (
        orderList[columnSortedTasks.inProgress[i]] &&
        !orderList[columnSortedTasks.inProgress[i]].workTime.paused
      ) {
        ordersOnWorkingTime.push(orderList[columnSortedTasks.inProgress[i]])
      }
    }
  }

  return ordersOnWorkingTime
}

export const getLocalOrderSorting = () => {
  if (localStorage && localStorage.getItem('orderSorting')) {
    let orderSorting = JSON.parse(localStorage.getItem('orderSorting'))
    return orderSorting
  } else {
    return null
  }
}

export const setLocalOrderSorting = (
  column = null,
  sortAsc = null,
  sortType = null
) => {
  let orderSorting = getLocalOrderSorting()
  if (column) {
    if (!orderSorting) {
      orderSorting = {}
    }
    if (!orderSorting[column]) {
      orderSorting[column] = {}
    }
    if (sortAsc !== null) orderSorting[column].sortAsc = sortAsc
    if (sortType !== null) orderSorting[column].sortType = sortType
    localStorage.setItem('orderSorting', JSON.stringify(orderSorting))
  }
}
