import React, { useMemo } from 'react'
import { typeDisplayAnnotation } from '../../shared/constants'
import PdfViewer from '../PdfViewer/PdfViewer'
import ImgViewer from '../ImgViewer/ImgViewer'

function AnnotationViewer({ typeDisplay, setCanDownloadFile }) {
  const viewer = useMemo(() => {
    switch (typeDisplay) {
      case typeDisplayAnnotation.IMAGE:
        return <ImgViewer setCanDownloadFile={setCanDownloadFile} />
      case typeDisplayAnnotation.PDF:
        return <PdfViewer setCanDownloadFile={setCanDownloadFile} />
      default:
        return null
    }
  }, [setCanDownloadFile, typeDisplay])

  return viewer
}

export default AnnotationViewer
