import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import * as Icon from 'react-feather'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import './Sidebar.scss'
// import * as actions from '../../store/actions/index';
import WorkingHours from './WorkingHours/WorkingHours'
import Membership from './Membership/Membership'
import { Can } from '../../context/abilityContext'
import { abilityAction, abilityComponent } from '../../shared/ability'
import clientLogin from '../../shared/clientLogin'
import { handleLogo } from '../../shared/utility'
import * as RouterPath from '../../shared/router'
import Button from '../Button/Button'

const Sidebar = ({ isFullScreen, onToggle, user, location, history }) => {
  let classes = 'sidebar'
  const clientId = localStorage.getItem('clientId')

  if (isFullScreen) {
    classes = 'sidebar hide'
  }

  const handleClickOutside = (e) => {
    if (e.target.className === 'sidebar-backdrop fade show') {
      onToggle()
    }
  }

  return (
    <React.Fragment>
      <div id="sidebar" className={classes}>
        <div className="d-flex sidebar-logo">
          <NavLink className="d-flex" to="/">
            {handleLogo(user)}
          </NavLink>
          <button
            type="button"
            className="btn btn-icon btn-toggle-sidebar d-flex ml-auto text-gray align-items-center"
            onClick={onToggle}
          >
            <Icon.LogOut className="feather" />
          </button>
        </div>
        <div className="user-component-detail">
          <div className="d-flex flex-column">
            <Membership />
            <Can I={abilityAction.READ} a={abilityComponent.QUOTATION}>
              <div className="d-flex flex-row mt-3 mb-3 row switch-order-quotation-container">
                <div className="col-6 d-flex flex-column pr-2">
                  <Button
                    className={`btn d-flex align-items-center justify-content-center go-to-orders ${
                      location &&
                      location.pathname !== RouterPath.quotationDashboard &&
                      location.pathname !== RouterPath.createQuotation &&
                      location.pathname !== RouterPath.archivedQuotation &&
                      !location.pathname.includes('/quotation/detail') &&
                      !location.pathname.includes('/duplicate-quotation')
                        ? 'btn-darkprimary'
                        : ''
                    }`}
                    onClick={() => history.push(RouterPath.home)}
                  >
                    Orders
                  </Button>
                </div>
                <div className="col-6 d-flex flex-column pl-2">
                  <Button
                    className={`btn d-flex align-items-center justify-content-center go-to-orders ${
                      location &&
                      (location.pathname === RouterPath.quotationDashboard ||
                        location.pathname === RouterPath.createQuotation ||
                        location.pathname === RouterPath.archivedQuotation ||
                        location.pathname.includes('/quotation/detail') ||
                        location.pathname.includes('/duplicate-quotation'))
                        ? 'btn-darkprimary'
                        : ''
                    }`}
                    onClick={() => history.push(RouterPath.quotationDashboard)}
                  >
                    Quotations
                  </Button>
                </div>
              </div>
            </Can>
            <div
              className={
                clientId === clientLogin.CUSTOMER
                  ? 'working-hours-container mt-4'
                  : 'working-hours-container'
              }
            >
              <WorkingHours />
            </div>
          </div>
        </div>
        {/* 
        {user && user.isClientWhitelabel && (
          <div className="user-switch-view-container">
            <p className="text-10 text-gray">Toggle view as</p>
            <button
              className="btn btn-darkprimary align-items-center w-100 text-14"
              onClick={() => toggleView(user)}
            >
              {getCurrentView(user) === 'customer'
                ? capitalizeFirstLetter('client')
                : capitalizeFirstLetter('customer')}
            </button>
          </div>
        )}
        */}
      </div>
      {!isFullScreen && (
        <div
          className="sidebar-backdrop fade show"
          onClick={handleClickOutside}
        ></div>
      )}
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  isFullScreen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
}

const mapStateToProps = ({ user }) => {
  return { user }
}

export default withRouter(connect(mapStateToProps)(Sidebar))
