import React, { useRef, useContext, useEffect, useCallback } from 'react'
import update from 'immutability-helper'

import PdfRender from '../PdfRender/PdfRender'
import PdfToolsRenderer from '../PdfRender/PdfToolsRenderer'
import CanvasContainer from '../Canvas/CanvasContainer'
import { TooltipContext } from '../../context/tooltipContext'
import { PreviewContext } from '../../context/previewContext'
import { PdfContext } from '../../context/pdfContext'
import { ShapeContext } from '../../context/shapeContext'
import { VersionContext } from '../../context/versionContext'
import CanvasView from '../CanvasView/CanvasView'
import { pdfAnnotationModules } from '../../shared/constants'

function PdfViewer({ setCanDownloadFile }) {
  const pdfViewerWrappRef = useRef(null)
  const tooltipContext = useContext(TooltipContext)
  const previewContext = useContext(PreviewContext)
  const { isNewVersionCanvas } = useContext(ShapeContext)
  const { selectedVersion } = useContext(VersionContext)

  const { onShowTooltip } = tooltipContext
  const { onImageBoundRectChanged } = previewContext

  const {
    updateLayerPdfScroll,
    updateActivePage,
    updatePdfViewerWrappRef,
  } = useContext(PdfContext)

  const updatePdfViewerWrappRefCb = useCallback(updatePdfViewerWrappRef, [])

  useEffect(() => {
    if (pdfViewerWrappRef.current) {
      updatePdfViewerWrappRefCb(pdfViewerWrappRef)
    }
  }, [updatePdfViewerWrappRefCb])

  /**
   * handle pdf width & height by element scroll render
   */
  const handleScrollPdf = useCallback(
    (e) => {
      onShowTooltip(false)

      const pdfViewerWrapperEl = e.currentTarget
      const viewerContainerEls = pdfViewerWrapperEl.getElementsByClassName(
        'pdf-viewer'
      )
      if (viewerContainerEls.length > 0) {
        const el = viewerContainerEls[0]
        const viewerContainerClientRect = el.getBoundingClientRect()

        onImageBoundRectChanged((x) =>
          update(x, {
            width: { $set: viewerContainerClientRect.width },
            height: { $set: viewerContainerClientRect.height },
            x: { $set: viewerContainerClientRect.x },
            y: { $set: viewerContainerClientRect.y },
          })
        )

        // set active page by scroll
        if (el.getElementsByClassName('page').length > 0) {
          const currentScroll = Math.abs(pdfViewerWrapperEl.scrollTop - 200)
          const heightPage = parseInt(
            el.getElementsByClassName('page')[0].offsetHeight
          )
          const currentPage = Math.ceil(currentScroll / (heightPage + 30))
          updateActivePage(currentPage)
        }
      }

      // used for group canvas x,y with document
      updateLayerPdfScroll({
        x: pdfViewerWrapperEl.scrollLeft,
        y: pdfViewerWrapperEl.scrollTop,
      })
    },
    [
      onImageBoundRectChanged,
      onShowTooltip,
      updateActivePage,
      updateLayerPdfScroll,
    ]
  )

  return (
    <div
      className="pdf-viewer-wrapper"
      ref={pdfViewerWrappRef}
      onScroll={handleScrollPdf}
    >
      {selectedVersion &&
      selectedVersion.pdfAnnotationModule === pdfAnnotationModules.pdfTools ? (
        <PdfToolsRenderer />
      ) : (
        <PdfRender />
      )}

      {selectedVersion &&
      selectedVersion.pdfAnnotationModule !== pdfAnnotationModules.pdfTools ? (
        isNewVersionCanvas ? (
          <CanvasView />
        ) : (
          <CanvasContainer />
        )
      ) : null}
    </div>
  )
}

export default React.memo(PdfViewer)
