import React from 'react'

export const OrderFilterContext = React.createContext({
  customer: {
    value: null,
    options: null,
    onChange: null,
  },
  orderStatus: {
    value: null,
    options: null,
    onChange: null,
  },
  orderAssigned: {
    value: null,
    options: null,
    onChange: null,
  },
  serviceCategory: {
    value: null,
    options: null,
    onChange: null,
  },
  serviceType: {
    value: null,
    options: null,
    onChange: null,
  },
  orderCreated: {
    startDate: null,
    endDate: null,
    onChange: null,
  },
  orderDueDate: {
    startDate: null,
    endDate: null,
    onChange: null,
  },
  queueTime: {
    value: null,
    options: null,
    onChange: null,
  },
  workTime: {
    value: null,
    options: null,
    onChange: null,
  },
  customSearch: {
    value: '',
    onChange: null,
  },
  dynamic: {
    value: null,
    options: null,
    onChange: null,
  },
  primary: {
    value: null,
    options: null,
    onChange: null,
  },
  presetName: {
    value: '',
    isEmpty: false,
    onChange: null,
    onKeyDown: null,
  },
  savePreset: null,
})
