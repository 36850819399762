import React, { useContext, useEffect } from 'react'
import * as Icon from 'react-feather'

import './OrderFilterNavBar.scss'
import InputField from '../Form/InputField/InputField'
import SelectField from '../Form/SelectField/SelectField'
import { OrderFilterContext } from '../../context/orderFilterContext'
import PresetFilterListNavBar from './PresetFilterListNavBar'
import userRole from '../../shared/userRole'
import DateRangeColumnPicker from '../Form/DateRangeColumnPicker/DateRangeColumnPicker'

const OrderFilterNavBar = ({
  height,
  onEdit,
  onExpandFilter,
  onReset,
  isExpand,
  user,
  openCategory,
  setOpenCategory,
  isDynamicOpen,
  setIsDynamicOpen,
  currentDynamicInput,
  setCurrentDynamicInput,
  isPresetOpen,
  setIsPresetOpen,
}) => {
  const orderFilterContext = useContext(OrderFilterContext)

  const handleSave = () => {
    if (typeof orderFilterContext.savePreset === 'function') {
      orderFilterContext.savePreset()
    }
  }

  const handleOnFocusOfPrimaryValue = (filter) => {
    switch (filter.value) {
      case 'client':
        orderFilterContext.client.onFocus()
        break
      case 'customer':
        orderFilterContext.customer.onFocus()
        break
      case 'order-creator':
        orderFilterContext.creator.onFocus()
        break
      case 'order-manager':
        orderFilterContext.manager.onFocus()
        break
      case 'order-assigned':
        orderFilterContext.orderAssigned.onFocus()
        break
      case 'order-status':
        break
      case 'order-services-category':
        orderFilterContext.serviceCategory.onFocus()
        break
      case 'order-services-type':
        orderFilterContext.serviceType.onFocus()
        break
      case 'order-created-date':
        break
      case 'order-due-date':
        break
      case 'order-queue-time':
        break
      case 'order-work-time':
        break
      default:
    }
  }

  const handleGetOptionsOfPrimaryValue = (filter) => {
    switch (filter.value) {
      case 'client':
        return orderFilterContext.client.options
      case 'customer':
        return orderFilterContext.customer.options
      case 'order-creator':
        return orderFilterContext.creator.options
      case 'order-manager':
        return orderFilterContext.manager.options
      case 'order-assigned':
        return orderFilterContext.orderAssigned.options
      case 'order-status':
        return orderFilterContext.orderStatus.options
      case 'order-services-category':
        return orderFilterContext.serviceCategory.options
      case 'order-services-type':
        return orderFilterContext.serviceType.options
      case 'order-created-date':
        return orderFilterContext.orderCreated.options
      case 'order-due-date':
        return orderFilterContext.orderDueDate.options
      case 'order-queue-time':
        return orderFilterContext.queueTime.options
      case 'order-work-time':
        return orderFilterContext.workTime.options
      default:
        return []
    }
  }

  const handleGetValueOfPrimaryValue = (filter) => {
    switch (filter.value) {
      case 'client':
        return orderFilterContext.client.value
      case 'customer':
        return orderFilterContext.customer.value
      case 'order-creator':
        return orderFilterContext.creator.value
      case 'order-manager':
        return orderFilterContext.manager.value
      case 'order-assigned':
        return orderFilterContext.orderAssigned.value
      case 'order-status':
        return orderFilterContext.orderStatus.value
      case 'order-services-category':
        return orderFilterContext.serviceCategory.value
      case 'order-services-type':
        return orderFilterContext.serviceType.value
      case 'order-created-date':
        return orderFilterContext.orderCreated.value
      case 'order-due-date':
        return orderFilterContext.orderDueDate.value
      case 'order-queue-time':
        return orderFilterContext.queueTime.value
      case 'order-work-time':
        return orderFilterContext.workTime.value
      default:
        return []
    }
  }

  const handleOnChangeOfPrimaryTitle = (filter, index) => {
    const newPrimaryFilter = [...orderFilterContext.primary.value]
    if (index !== -1) {
      newPrimaryFilter[index] = {
        ...filter,
        options: defaultPrimaryOptions,
      }
    }
    orderFilterContext.primary.onChange(newPrimaryFilter)
    handleOnFocusOfPrimaryValue(filter)
  }

  const handleOnChangeOfPrimaryValue = (filter) => {
    switch (filter.value) {
      case 'client':
        return orderFilterContext.client.onChange
      case 'customer':
        return orderFilterContext.customer.onChange
      case 'order-creator':
        return orderFilterContext.creator.onChange
      case 'order-manager':
        return orderFilterContext.manager.onChange
      case 'order-assigned':
        return orderFilterContext.orderAssigned.onChange
      case 'order-status':
        return orderFilterContext.orderStatus.onChange
      case 'order-services-category':
        return orderFilterContext.serviceCategory.onChange
      case 'order-services-type':
        return orderFilterContext.serviceType.onChange
      case 'order-created-date':
        return orderFilterContext.orderCreated.onChange
      case 'order-due-date':
        return orderFilterContext.orderDueDate.onChange
      case 'order-queue-time':
        return orderFilterContext.queueTime.onChange
      case 'order-work-time':
        return orderFilterContext.workTime.onChange
      default:
        return []
    }
  }

  const handleDeleteOfPrimaryTitle = (filter) => {
    switch (filter.value) {
      case 'client':
        return orderFilterContext.client.onChange(null)
      case 'customer':
        return orderFilterContext.customer.onChange(null)
      case 'order-creator':
        return orderFilterContext.creator.onChange(null)
      case 'order-manager':
        return orderFilterContext.manager.onChange(null)
      case 'order-assigned':
        return orderFilterContext.orderAssigned.onChange(null)
      case 'order-status':
        return orderFilterContext.orderStatus.onChange(null)
      case 'order-services-category':
        return orderFilterContext.serviceCategory.onChange(null)
      case 'order-services-type':
        return orderFilterContext.serviceType.onChange(null)
      case 'order-created-date':
        return orderFilterContext.orderCreated.onChange({
          startDate: null,
          endDate: null,
        })
      case 'order-due-date':
        return orderFilterContext.orderDueDate.onChange({
          startDate: null,
          endDate: null,
        })
      case 'order-queue-time':
        return orderFilterContext.queueTime.onChange(null)
      case 'order-work-time':
        return orderFilterContext.workTime.onChange(null)
      default:
        return null
    }
  }

  const handleGetValueOfStartDate = (filter) => {
    switch (filter.value) {
      case 'order-created-date':
        return orderFilterContext.orderCreated.startDate
      case 'order-due-date':
        return orderFilterContext.orderDueDate.startDate
      default:
        return []
    }
  }

  const handleGetValueOfEndDate = (filter) => {
    switch (filter.value) {
      case 'order-created-date':
        return orderFilterContext.orderCreated.endDate
      case 'order-due-date':
        return orderFilterContext.orderDueDate.endDate
      default:
        return []
    }
  }

  const addNewPrimaryFilter = () => {
    const value = orderFilterContext.primary.value
    if (value[value.length - 1].value !== '') {
      const newValue = [...value, defaultPrimaryValue]
      orderFilterContext.primary.onChange(newValue)
    }
  }

  const handleDeleteOptionsOfDynamicTitle = (
    filterToRemove,
    dynamicFilter,
    primaryFilter
  ) => {
    const primaryValues = [...primaryFilter]
    const dynamicValues = [...dynamicFilter]
    const primaryIndex = primaryValues.findIndex(
      (pv) => pv.value === filterToRemove.value
    )
    if (primaryIndex !== -1) {
      primaryValues.splice(primaryIndex, 1)
    }
    const dynamicIndex = dynamicValues.findIndex(
      (dv) => dv._id === filterToRemove.value
    )
    if (dynamicIndex !== -1) {
      dynamicValues.splice(dynamicIndex, 1)
    }
    if (primaryValues.length === 0) {
      onReset()
    } else {
      orderFilterContext.primary.onChange(primaryValues)
      orderFilterContext.dynamic.onChange(dynamicValues)
    }
  }

  const handleDeleteOptionsOfPrimaryTitle = (filter, primaryFilter) => {
    const values = [...primaryFilter]
    const index = values.findIndex((v) => v.value === filter.value)
    if (index !== -1) {
      values.splice(index, 1)
    }
    if (values.length === 0) {
      onReset()
    } else {
      orderFilterContext.primary.onChange(values)
    }
    handleDeleteOfPrimaryTitle(filter)
  }

  const handleIsEmptyOfDynamicValue = (filter, dynamicFilter) => {
    if (filter.level === 'primary' || dynamicFilter.length === 0) {
      const value = handleGetValueOfPrimaryValue(filter)
      return value.length > 0
    } else if (filter.level === 'dynamic') {
      const dynamicValue = [...dynamicFilter]
      const activeDynamicFilter = dynamicValue.filter(
        (dv) => dv._id === filter.value
      )
      if (
        activeDynamicFilter.length !== 0 ??
        activeDynamicFilter[0].value !== ''
      ) {
        return true
      } else {
        return false
      }
    }
  }

  const handleGetDynamicValueOfPrimaryValue = (filter, dynamicFilter) => {
    // todo fix uncontrolled to controlled
    let value = undefined
    const dynamicValue = [...dynamicFilter]

    const isFilterIdExistInDynamicFilter = dynamicValue.findIndex(
      (f) => f._id === filter.value
    )

    if (isFilterIdExistInDynamicFilter !== -1) {
      return dynamicFilter[isFilterIdExistInDynamicFilter].value
    } else {
      return value
    }
  }

  useEffect(() => {
    setOpenCategory('none')
    setIsDynamicOpen(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <button
        onClick={onExpandFilter}
        className="btn btn-outline-primary action-button"
      >
        <Icon.Filter className="feather" size={20} />
        <div>Filter</div>
      </button>
      {isExpand && (
        <div className="filter-content">
          <div className="filter-header-row">
            <div className="filter-header-row-title">Orders Advance Filter</div>
            <div className="filter-header-row-action">
              <button className="btn disabled" onClick={onReset}>
                Clear all
              </button>
              <button
                onClick={() => setIsPresetOpen((prevState) => !prevState)}
                className="btn btn-outline-primary action-button"
              >
                <div style={{ fontSize: '10px', zIndex: '1058' }}>
                  Saved Filters
                </div>
                <Icon.ChevronDown size={14} />
              </button>
              {isExpand && isPresetOpen && (
                <>
                  <div className="preset-section">
                    <div>MY FILTERS</div>
                    <InputField
                      placeholder="Filter Name"
                      maxLength={30}
                      isRequired
                      isEmpty={orderFilterContext.presetName.isEmpty}
                      value={orderFilterContext.presetName.value}
                      onChange={orderFilterContext.presetName.onChange}
                      onKeyDown={orderFilterContext.presetName.onKeyDown}
                    />
                    <button
                      type="button"
                      className="btn action-button"
                      onClick={handleSave}
                      disabled={
                        user && user.viewOnly && user.role !== userRole.CSO
                      }
                    >
                      <Icon.Download size={16} />
                      <div style={{ fontSize: '12px', fontWeight: '300' }}>
                        Save Preset Filter
                      </div>
                    </button>
                    <PresetFilterListNavBar
                      onEdit={onEdit}
                      onReset={onReset}
                      onModalClose={setIsPresetOpen}
                    />

                    {/* <div className="separator" />
                  <div>WORKSPACE FILTERS</div>
                  <div>Save active filters</div>
                  <div className="filter-preset"></div> */}
                  </div>
                  <div
                    onClick={() => setIsPresetOpen(false)}
                    className="overlay"
                  />
                </>
              )}
            </div>
          </div>
          <div className="separator" />
          <div className="filter-default-body">
            {orderFilterContext.primary.value.map((p, i) => (
              <div className="body-row" key={p.value}>
                <div className="body-row-content">
                  <SelectField
                    fullwidth
                    placeholder="Select parameter"
                    className={`bg-white row-title ${
                      handleIsEmptyOfDynamicValue(
                        p,
                        orderFilterContext.dynamic.value
                      )
                        ? 'disabled'
                        : ''
                    }`}
                    closeMenuOnSelect
                    isScrollToSelected={false}
                    isDisabled={handleIsEmptyOfDynamicValue(
                      p,
                      orderFilterContext.dynamic.value
                    )}
                    selectedValue={p.value !== '' ? p : ''}
                    options={orderFilterContext.primary.options}
                    onChange={(e) => handleOnChangeOfPrimaryTitle(e, i)}
                    onFocus={orderFilterContext.primary.onFocus}
                    menuPosition={'fixed'}
                    closeMenuOnScroll={() => false}
                    customStyle={{
                      menuPortal: (base) => ({ ...base, zIndex: 80 }),
                    }}
                  />
                  {p.type === 'select' ? (
                    <SelectField
                      placeholder="Select value"
                      className="bg-white row-value"
                      isMulti
                      isClearable
                      closeMenuOnSelect
                      isScrollToSelected={false}
                      selectedValue={handleGetValueOfPrimaryValue(p)}
                      options={handleGetOptionsOfPrimaryValue(p)}
                      onChange={handleOnChangeOfPrimaryValue(p)}
                      menuPosition={'fixed'}
                      closeMenuOnScroll={() => false}
                      customStyle={{
                        menuPortal: (base) => ({ ...base, zIndex: 80 }),
                      }}
                    />
                  ) : p.type === 'date' ? (
                    <DateRangeColumnPicker
                      formClassName="date-input"
                      startDate={handleGetValueOfStartDate(p)}
                      endDate={handleGetValueOfEndDate(p)}
                      onChange={handleOnChangeOfPrimaryValue(p)}
                    />
                  ) : p.type === 'text' && p.level === 'dynamic' ? (
                    <InputField
                      style={{ width: '260px' }}
                      placeholder="Input Value"
                      maxLength={60}
                      value={handleGetDynamicValueOfPrimaryValue(
                        p,
                        orderFilterContext.dynamic.value
                      )}
                      onChange={(e) =>
                        orderFilterContext.dynamic.throttledOnChange(p, e)
                      }
                    />
                  ) : null}
                </div>
                <div className="body-row-action">
                  {p.level === 'primary' ? (
                    <Icon.Trash2
                      onClick={() =>
                        handleDeleteOptionsOfPrimaryTitle(
                          p,
                          orderFilterContext.primary.value
                        )
                      }
                      size={20}
                    />
                  ) : p.level === 'dynamic' ? (
                    <Icon.Trash2
                      onClick={() =>
                        handleDeleteOptionsOfDynamicTitle(
                          p,
                          orderFilterContext.dynamic.value,
                          orderFilterContext.primary.value
                        )
                      }
                      size={20}
                    />
                  ) : null}
                </div>
              </div>
            ))}
          </div>
          <div>
            <div className="separator" />
            <button
              onClick={addNewPrimaryFilter}
              className="btn btn-outline-primary action-button"
            >
              <Icon.Plus size={20} />
              <div> Add Filter</div>
            </button>
          </div>
        </div>
      )}
      <div
        className="overlay"
        style={{ display: isExpand ? 'block' : 'none' }}
        onClick={onExpandFilter}
      />
    </>
  )
}

export default OrderFilterNavBar

const defaultPrimaryOptions = [
  {
    value: 'client',
    label: 'Client',
    type: 'select',
    level: 'primary',
  },
  {
    value: 'customer',
    label: 'Customer',
    type: 'select',
    level: 'primary',
  },
  {
    value: 'order-creator',
    label: `Order's Creator`,
    type: 'select',
    level: 'primary',
  },
  {
    value: 'order-manager',
    label: `Project Lead`,
    type: 'select',
    level: 'primary',
  },
  {
    value: 'order-assigned',
    label: `Order's Assigned`,
    type: 'select',
    level: 'primary',
  },
  {
    value: 'order-status',
    label: `Order's Status`,
    type: 'select',
    level: 'primary',
  },
  {
    value: 'order-services-category',
    label: `Order's Services Category`,
    type: 'select',
    level: 'primary',
  },
  {
    value: 'order-services-type',
    label: `Order's Services Type`,
    type: 'select',
    level: 'primary',
  },
  {
    value: 'order-created-date',
    label: `Order's Created Date`,
    type: 'date',
    level: 'primary',
  },
  {
    value: 'order-due-date',
    label: `Order's Due Date`,
    type: 'date',
    level: 'primary',
  },
  {
    value: 'order-queue-time',
    label: `Order's Queue Time`,
    type: 'select',
    level: 'primary',
  },
  {
    value: 'order-work-time',
    label: `Order's Work Time`,
    type: 'select',
    level: 'primary',
  },
]

const defaultPrimaryValue = {
  value: '',
  label: '',
  options: defaultPrimaryOptions,
  type: 'disabled',
  level: 'primary',
}
