import React, { useState, useEffect, useRef } from 'react'
import { withRouter, NavLink } from 'react-router-dom'
import { Transition } from 'react-transition-group'
import feather from 'feather-icons'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import './UserMenu.scss'
import * as Router from '../../../shared/router'
import userRole from '../../../shared/userRole'
import Avatar from '../../Avatar/Avatar'
import { abilityAction, abilityComponent } from '../../../shared/ability'
import { Can } from '../../../context/abilityContext'
import { truncateText, getCurrentView } from '../../../shared/utility'
import clientLogin from '../../../shared/clientLogin'
import { getRoleTitle } from '../../../shared/userHelper'
import {
  menuUserService,
  menuUserCloseService,
} from '../../../services/tourGuideService/menuUserService'

const duration = 300

const defaultUserMenuStyle = {
  transition: `all ${duration}ms ease-in-out`,
  visibility: 'visible',
  opacity: 0,
}

const transitionUserMenuStyles = {
  entered: { opacity: 1, visibility: 'visible' },
  exited: { opacity: 0, visibility: 'hidden' },
}

const UserMenu = ({ userDetail, history }) => {
  const [isUserMenuVisible, setIsUserMenuVisible] = useState(false)
  const wrapperRef = useRef(null)
  const [isUserMenuTourGuide, setIsUserMenuTourGuide] = useState(false)

  let activeClass = isUserMenuVisible ? 'active' : ''
  let clientId = localStorage.getItem('clientId')

  const handleUserMenu = () => {
    setIsUserMenuVisible(!isUserMenuVisible)
    setTimeout(() => {
      // call tour guide
      menuUserService.emitReload(true)
    }, 320)
  }

  useEffect(() => {
    feather.replace()

    menuUserCloseService.reload().subscribe((value) => {
      // listen tour guide to able close menu on click outside
      setIsUserMenuTourGuide(value)
    })
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  const handleClickOutside = (e) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(e.target) &&
      !isUserMenuTourGuide
    ) {
      setIsUserMenuVisible(false)
    }
  }

  const handleCloseMenu = () => {
    setIsUserMenuVisible(false)
  }

  return (
    <div className="top-link-user" ref={wrapperRef}>
      <button
        type="button"
        className={`btn btn-icon btn-top-nav ${activeClass}`}
        onClick={handleUserMenu}
      >
        <i data-feather="user"></i>
      </button>
      <Transition in={isUserMenuVisible} timeout={0}>
        {(state) => (
          <div
            className="menu-user"
            style={{
              ...defaultUserMenuStyle,
              ...transitionUserMenuStyles[state],
            }}
          >
            {userDetail && (
              <div className="d-flex align-items-center users-detail">
                <Avatar
                  src={userDetail.avatar ? userDetail.avatar : null}
                  user={userDetail}
                  radius={50}
                />
                <div className="ml-4">
                  <div className="menu-user-fullname font-weight-bold user-complete-name">
                    <span>{`${truncateText(
                      userDetail.firstname + ' ' + userDetail.lastname,
                      24
                    )}`}</span>
                  </div>
                  <div>
                    <div className="menu-user-desc user-job-title">
                      {clientId && clientId === clientLogin.CUSTOMER ? (
                        <span className="text-primary">
                          {truncateText(userDetail.jobTitle, 24)}
                        </span>
                      ) : (
                        <span className="text-primary">
                          {getRoleTitle(userDetail.role)}
                        </span>
                      )}
                    </div>
                    <div className="menu-user-desc user-email-address">
                      <span className="text-danger">
                        {userDetail.emailAddress}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="mt-4">
              <ul className="nav flex-column m-0">
                <Can
                  I={abilityAction.READ}
                  a={abilityComponent.NAV_MANAGE_PROFILE}
                >
                  <li className="nav-item">
                    <NavLink
                      to={Router.manageProfile}
                      className="btn btn-icon manage-profile-menu"
                      onClick={handleCloseMenu}
                    >
                      <span>Manage Profile</span>
                    </NavLink>
                  </li>
                </Can>

                <Can
                  I={abilityAction.READ}
                  a={abilityComponent.NAV_PEXELS_CUSTOMER_IMAGES}
                >
                  <li className="nav-item">
                    <NavLink
                      to={Router.myImages}
                      className="btn btn-icon manage-my-images"
                      onClick={handleCloseMenu}
                    >
                      <span>My Images</span>
                    </NavLink>
                  </li>
                </Can>

                {userDetail && userDetail.isSubscriber && (
                  <Can
                    I={abilityAction.READ}
                    a={abilityComponent.NAV_MANAGE_MEMBERSHIP}
                  >
                    <li className="nav-item">
                      <NavLink
                        to={Router.managePackage}
                        className="btn btn-icon manage-payment-menu"
                        onClick={handleCloseMenu}
                      >
                        <span>Membership &amp; Additional Resources</span>
                      </NavLink>
                    </li>
                  </Can>
                )}

                {userDetail && userDetail.invoiceType !== 'without' && (
                  <Can I={abilityAction.READ} a={abilityComponent.NAV_PAYMENT}>
                    <li className="nav-item">
                      <NavLink
                        to={Router.invoices}
                        className="btn btn-icon manage-payment-menu"
                        onClick={handleCloseMenu}
                      >
                        <span>
                          {userDetail.isManualInvoice === true
                            ? 'Manage Invoices'
                            : 'Manage Payment/Invoices'}
                        </span>
                      </NavLink>
                    </li>
                  </Can>
                )}

                <Can I={abilityAction.READ} a={abilityComponent.REPORT}>
                  <li className="nav-item">
                    <NavLink
                      to={Router.usageReporting}
                      className="btn btn-icon manage-report"
                      onClick={handleCloseMenu}
                    >
                      <span>Usage Reporting</span>
                    </NavLink>
                  </li>
                </Can>

                {userDetail && getCurrentView(userDetail) === 'customer' && (
                  <Can
                    I={abilityAction.READ}
                    a={abilityComponent.NAV_TEAM_MEMBER}
                  >
                    <li className="nav-item">
                      <NavLink
                        to={Router.teamMember}
                        className="btn btn-icon manage-team-member-menu"
                        onClick={handleCloseMenu}
                      >
                        <span>Manage Team Member</span>
                      </NavLink>
                    </li>
                  </Can>
                )}
                {userDetail && userDetail.role !== userRole.WHITELABEL && (
                  <li className="nav-item">
                    <NavLink
                      to={Router.printServiceProvider}
                      className="btn btn-icon manage-psp-menu"
                      onClick={handleCloseMenu}
                    >
                      <span>Print Service Provider</span>
                    </NavLink>
                  </li>
                )}

                <li className="nav-item">
                  <NavLink
                    to={Router.generalSetting}
                    className="btn btn-icon general-settings-menu"
                    onClick={handleCloseMenu}
                  >
                    <span>General Settings</span>
                  </NavLink>
                </li>

                {userDetail && userDetail.myProofApprovalWorkflowPreference && (
                  <Can
                    I={abilityAction.VISIT}
                    a={abilityComponent.PAGE_WORKFLOW_EDITOR}
                  >
                    <li className="nav-item">
                      <NavLink
                        to={Router.workflowAndRoles}
                        className="btn btn-icon manage-psp-menu"
                        onClick={handleCloseMenu}
                      >
                        <span>Workflow and Roles</span>
                      </NavLink>
                    </li>
                  </Can>
                )}

                <li className="nav-item">
                  <NavLink
                    to={Router.signout}
                    className="btn btn-icon logout-menu"
                  >
                    <span>Logout</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        )}
      </Transition>
    </div>
  )
}

UserMenu.propTypes = {
  userDetail: PropTypes.object,
}

const mapStateToProps = ({ user }) => {
  return { userDetail: user }
}

export default withRouter(connect(mapStateToProps)(UserMenu))
